export const isCardWild = ({suit,rank}) => {
    return suit === 'joker' || rank === '2' ;
}

export const canAcceptWildCardsInMeld = (meld) => {
    const wildCards = meld.meldCards.filter(card => card.card.isWild).length;
    const naturalCards = meld.meldCards.length - wildCards;

    const maxAllowedWildCards = getNumberOfWildCardsAllowed(meld);

    // Ensure there is at least one more natural card than wild cards
    const hasCorrectRatio = naturalCards > wildCards;

    // Ensure total cards in the meld do not exceed 7
    const totalCardsLimit = meld.meldCards.length <= 7;

    return wildCards <= maxAllowedWildCards && hasCorrectRatio && totalCardsLimit;
};

export const getNumberOfWildCardsAllowed = (meld, gameConfig) => {
    const wildCards = meld.meldCards.filter(card => card.card.isWild).length;
    const naturalCards = meld.meldCards.length - wildCards;
    const totalCards = wildCards + naturalCards;

    if (naturalCards < 2) {
        return 0;
    }

    if (naturalCards === 2 && wildCards === 1) {
        return 0;
    }

    const maxWildCardsConfig = gameConfig ? gameConfig.maxWildCards : 3;

    return Math.max(Math.min(Math.min(7 - totalCards, naturalCards - wildCards - 1), maxWildCardsConfig - wildCards), 0);
}

export const hasMinimumNaturalCards = (meld) => {
    return meld.meldCards.filter(card => !card.card.isWild).length >= 2;
}

export const isCanasta = (meld) => {
    return meld && meld.meldCards.length >= 7;
}

// export const getTotalNaturalCanastas = (team) => team && team.melds ? 
// team.melds.reduce((sum, meld) => meld.isNatural && isCanasta(meld) ? sum + 1 : sum ,0) : 0;

export const getTotalNaturalCanastas = (team) => {
    if (!team || !team.melds) return 0;  
    
    return team.melds.reduce((sum, meld) => 
    meld.isNatural && isCanasta(meld) ? sum + 1 : sum,0);
};

export const getTotalUnnaturalCanastas = (team) => {
    if (!team || !team.melds) return 0;  
    
    return team.melds.reduce((sum, meld) => 
    !meld.isNatural && isCanasta(meld) ? sum + 1 : sum,0);
};

export const getNaturalCanastasLeft = (team, naturalCanastasNeeded, boundToZero = true) => {
    if (!team || !team.melds) return 0;  

    return team.melds.reduce((acc, meld) => meld.isNatural && isCanasta(meld) && (!boundToZero || acc > 0) ? acc - 1 : acc, naturalCanastasNeeded);
};

export const getTotalUnnaturalCanastasLeft = (team, unnaturalCanastasNeeded, boundToZero = true) => {
    if (!team || !team.melds) return 0;  

    return team.melds.reduce((acc, meld) => !meld.isNatural && isCanasta(meld) && (!boundToZero || acc > 0) ? acc - 1 : acc, unnaturalCanastasNeeded);
};

export const naturalCanastaIsNotAllowed = (meld, team, game) => {
    if (!meld || !team || !game) {
        return false;
    }

    return (
        meld && meld.meldCards.length === 6 &&
        meld.meldCards.every(card => !card.card.isWild) &&
        getTotalNaturalCanastas(team) >= game.gameConfig.naturalCanastasNeeded &&
        team.unnaturalCanastasLeft > 0
    );
}

export const unnaturalCanastaIsNotAllowed = (meld, team, game) => {
    if (!meld || !team || !game) {
        return false;
    }

    return (        
        getTotalUnnaturalCanastas(team) >= game.gameConfig.unnaturalCanastasNeeded
    );
}

export const isPlayerCannotMakeMeldWithACard = (cardRank, meld, team) => {
    if (!team || !team.teamPlayers || !team.teamPlayers[0] || !team.teamPlayers[0].hand) {
        return false;
    }
    const playerCards = team.teamPlayers[0].hand.handCards;
    if (!playerCards) return false;
    const numberOfSpecificCards = playerCards.reduce((sum, {card}) => 
    card.cardRank.value == cardRank ? sum + 1 : sum, 0);
    const numberOfWildCards = playerCards.reduce((sum, {card}) => card.isWild ? sum + 1 : sum, 0);

    return (
        (!meld || meld.meldCards.length === 0)  && 
        (numberOfSpecificCards === 1 ||    
        (numberOfSpecificCards === 2 && numberOfWildCards === 0))
    );
}

export const meldHasValidNumberOfWildCards = (meld, maxWildCardsAllowed) => {
    let wildCards = 0;

    for (const meldCard of meld.meldCards) {
        if (meldCard.card.isWild) {
            wildCards++;
        }
    }

    return wildCards <= maxWildCardsAllowed;
}

export const meldHasValidRateOfWildCards = (meld) => {
    let wildCards = 0;
    let naturalCards = 0;

    for (const meldCard of meld.meldCards) {
        if (meldCard.card.isWild) {
            wildCards++;
        } else {
            naturalCards++;
        }
    }

    return wildCards == 0 || (naturalCards >= 2 && wildCards < naturalCards);
}

class GameEngine {
    performPlayerAction(originalState, action, cardIds, extraArgs) {
        const newState = JSON.parse(JSON.stringify(originalState));
        switch (action) {
            case 'MELD': {
                const meldRank = extraArgs && extraArgs[0] ? extraArgs[0] : null;
                const metadata = this.metadata(newState);
                const { meld } = this.meldCard(newState, cardIds, meldRank, metadata);
                this.swipeCardsIfRelevant(meld, newState, metadata);

                const playerGetsFoot =
                    !metadata.currentPlayer.isPlayingFoot &&
                    metadata.currentPlayer.hand &&
                    metadata.currentPlayer.hand.handCards.length > 0 &&
                    this.allMeldsComplete(metadata.currentTeam);

                if (playerGetsFoot) {
                    metadata.currentPlayer.isPlayingFoot = true;
                    metadata.currentPlayer.isPickingUpPile = false;
                    metadata.currentTeam.melds.forEach(meld => meld.meldCards.forEach(card => card.committed = true));
                }

                return newState;
            }
            case 'UNMELD': {
                const meldRank = extraArgs && extraArgs[0] ? extraArgs[0] : null;
                const metadata = this.metadata(newState);
                this.unmeld(meldRank, newState, metadata);
                return newState;
            }
            case 'UNMELD_ALL': {
                const metadata = this.metadata(newState);
                this.unmeldAll(newState,metadata);
                return newState;
            }
            default:
                return originalState;
        }
    }

    meldCard(state, cardIds, meldRank, metadata, skipEnoughCardsCheck = false) {
        const gameConfig = state.gameConfig;

        if (cardIds  && cardIds.length > 1) {
            throw new Error('You can only meld exactly 1 card');
        }

        if (metadata.currentPlayer.hand.handCards.length === 0) {
            throw new Error("Player doesn't have any hand");
        }

        const cardIdToMeld = cardIds && cardIds[0] ? cardIds[0] : null;

        let handCardToMeld = null;

        if (cardIdToMeld) {
            for (const handCard of metadata.currentPlayer.hand.handCards) {
                if (handCard.card.id === cardIdToMeld) {
                    handCardToMeld = handCard;
                    break;
                }
            }
        } else { // Player tapped on placeholder, let's look for a card in the player's hand that matches the rank of the tapped placeholder
            let handCardsOfMeldRank = metadata.currentPlayer.hand.handCards.filter(card => card.card.cardRank.value === meldRank);

            if (handCardsOfMeldRank.length === 0) {
                throw new Error('no such card');
            }

            const suitOrderMap = { c: 0, d: 1, h: 2, s: 3 };
            handCardsOfMeldRank = handCardsOfMeldRank.sort((a, b) => {
                return suitOrderMap[a.card.cardSuit.letter] - suitOrderMap[b.card.cardSuit.letter];
            });

            handCardToMeld = handCardsOfMeldRank[0];
        }

        if (!handCardToMeld) { // For some reason we couldn't find the card in the player's hand
            throw new Error("player doesn't have such a card in hand");
        }

        let cardRankToMeld = handCardToMeld.card.cardRank;
        let meldCardRank = null;

        if (meldRank) {
            meldCardRank = GameEngine.CARD_RANKS.find(rank => rank.value = meldRank);
            if (!meldCardRank) {
                throw new Error('invalid meld rank');
            }
        } else {
            meldCardRank = cardRankToMeld;
        }

        // Make sure the rank of the card and the rank of the meld match when the card is not a wild card.
        if (meldCardRank.value !== cardRankToMeld.value && !handCardToMeld.card.isWild) {
            throw new Error('this card cannot be added to this meld');
        }

        let meld = metadata.currentTeam.melds.find(meld => meld.cardRank.value === meldCardRank.value);
        if (!meld || meld.meldCards.length === 0) { // There is no meld for this rank yet
            if (handCardToMeld.card.isWild) {
                throw new Error("can't start meld with wild card");
            }

            const totalCardsAvailable = metadata.currentPlayer.hand.handCards.filter(card => card.card.cardRank.value === meldCardRank.value).length;
            const totlaWildCards = metadata.currentPlayer.hand.handCards.filter(card => card.card.isWild).length;


            // Player needs to have more than 2 cards of the rank OR 2 cards of the rank and a wild card
            if (!skipEnoughCardsCheck && !(totalCardsAvailable >= 3 || (totalCardsAvailable === 2 && totlaWildCards > 0))) {
                throw new Error('not enough cards to start this meld');
            }
        } else if (isCanasta(meld) && handCardToMeld.card.isWild) { // A meld exists and it's already a canasta, make sure we are not adding a wild card.
            throw new Error("can't add a wild card to a canasta");
        }

        // If this is a natural canasta that we are creating by adding a natural card to a natural meld,
        // make sure we don't exceed the minimum required of natural canastas
        // if we haven't reached the minimum required of unnatural canastas.
        if (
            meld && meld.meldCards.length === 6 &&
            meld.isNatural &&
            handCardToMeld.card.isWild &&
            getTotalNaturalCanastas(metadata.currentTeam) + 1 > gameConfig.naturalCanastasNeeded &&
            getTotalUnnaturalCanastasLeft(metadata.currentTeam, gameConfig.unnaturalCanastasNeeded) > 0
        ) {
            throw new Error('cannot create more natural canastas (by adding a natural card) until having the required amount of unnatural canastas');
        }

        // If this is a unnatural canasta that we are creating by addind a wild card to a natural meld,
        // make sure we don't exceed the minimum required of unnatural canastas
        // if we haven't reached the minimum required of natural canastas.
        if (
            meld && meld.meldCards.length === 6 &&
            meld.cardRank?.value &&
            handCardToMeld.card.isWild &&
            getTotalUnnaturalCanastas(metadata.currentTeam) + 1 > gameConfig.unnaturalCanastasNeeded &&
            getNaturalCanastasLeft(metadata.currentTeam, gameConfig.naturalCanastasNeeded) > 0
        ) {
            throw new Error("cannot create more unnatural canastas (by adding a wild card) until having the required amount of natural canastas");
        }

        // If this is an unnatural canasta that we are creating, make sure we don't exceed the minimum required of unnatural
        // canastas if we haven't reached the minimum required of natural canastas.
        if (
            meld && meld.meldCards.length === 6 &&
            !meld.isNatural &&
            getTotalNaturalCanastas(metadata.currentTeam) + 1 > gameConfig.naturalCanastasNeeded &&
            getNaturalCanastasLeft(metadata.currentTeam, gameConfig.naturalCanastasNeeded) > 0
        ) {
            throw new Error("cannot create more unnatural canastas (by adding a wild or natural card) until having the required amount of natural canastas");
        }

        // Create the meld if it didn't exist already
        if (!meld) {
            meld = {
                id: -1,
                isNatural: false,
                cardRank: meldCardRank,
                numberOfWildCardsAllowed: 0,
                meldCards: [],
            };
        }

        // Take the card from the hand and place it in the meld
        metadata.currentPlayer.hand.handCards =
            metadata.currentPlayer.hand.handCards.filter(card => card.card.id !== handCardToMeld.card.id);
        const meldCard = handCardToMeld;
        meld.meldCards.push(meldCard);

        meld.numberOfWildCardsAllowed = getNumberOfWildCardsAllowed(meld, gameConfig);

        // Make sure the meld has now a valid number of wild cards
        if (!meldHasValidNumberOfWildCards(meld, gameConfig.maxWildCards)) {
            throw new Error("meld has invalid number of wild cards");
        }

        if (!meldHasValidRateOfWildCards(meld)) {
            throw new Error("meld has invalid rate of wild cards");
        }

        return { meld, meldCard };
    }

    unmeld(meldRank, state, metadata) {
        if (!meldRank) {
            throw new Error("a meld rank needs to be specified");
        }

        const gameConfig = state.gameConfig;

        let meld = metadata.currentTeam.melds.find(meld => meld.cardRank.value === meldRank);
        let meldHasUncommittedCards = false;
        const meldCardsRemoved = [];

        meld.meldCards = meld.meldCards.filter((meldCard)  => {
            if (!meldCard.committed) {
                meldHasUncommittedCards = true;
                if (!metadata.currentPlayer.hand) { metadata.currentPlayer.hand = {} }
                if (!metadata.currentPlayer.hand.handCards) { metadata.currentPlayer.hand.handCards = []; }

                metadata.currentPlayer.hand.handCards.push(meldCard);
                meldCardsRemoved.push(meldCard);
                return false;
            }
            return true;
        });

        if (!meldHasUncommittedCards) {
            throw new Error("the meld doesn't have uncommitted cards to unmeld");
        }

        meld.numberOfWildCardsAllowed = getNumberOfWildCardsAllowed(meld, gameConfig);
        if (metadata.currentPlayer.hand?.handCards?.length > 0) {
            metadata.currentPlayer.hand.handCards = metadata.currentPlayer.hand.handCards.sort((a, b) => a.card.cardRank.sortOrder - b.card.cardRank.sortOrder);
        }

        return meldCardsRemoved;
    }

    unmeldAll(state, metadata) {
        let hasUncommittedMelds = false;
        const meldCardsRemoved = [];
        for (const meld of metadata.currentTeam.melds) {
            const meldHasUncommittedCards = meld.meldCards.some(card => !card.committed);
            if (meldHasUncommittedCards) {
                meldCardsRemoved.push(... this.unmeld(meld.cardRank.value, state, metadata));
                hasUncommittedMelds = true;
                this.meldUpdateNaturalFlag(meld);
            }
        }
        return meldCardsRemoved;
    }

    metadata(state) {
        let currentPlayer = null;
        let currentTeam = null;
        let opponentTeam = null;

        for (const team of state.gameTeams) {
            let isCurrentPlayerTeam = false;
            for (const player of team.teamPlayers) {
                if (player.isCurrentTurn) {
                    currentPlayer = player;
                    currentTeam = team;
                    isCurrentPlayerTeam = true;
                }
            }

            if (!isCurrentPlayerTeam) {
                opponentTeam = team;
            }
        }

        return {
            currentPlayer,
            currentTeam,
            opponentTeam
        };
    }

    swipeCardsIfRelevant(meld, state, metadata) {
        const opponentTeam = metadata.opponentTeam;
        const gameConfig = state.gameConfig;

        if (!gameConfig.swipeCards || !isCanasta(meld) || meld.meldCards.length != 7) {
            return false;
        }

        const cardRank = meld.cardRank;
        const opponentTeamMeld = opponentTeam.melds.find(meld => meld.cardRank.value === cardRank.value);

        if (!opponentTeamMeld || !isCanasta(opponentTeamMeld)) {
            return false;
        }

        opponentTeamMeld.meldCards = [];

        return true;
    }

    attemptToGivePickUpCards(state, metadata) {
        const team = metadata.currentTeam;
        const opponentTeam = metadata.opponentTeam;
        const player = metadata.currentPlayer;
        const topCard = state.discardPileTopCard;

        const topCardMeld = team.melds.find(meld => meld.cardRank.value === topCard.cardRank.value);
        const hasMeldedRequiredCards = topCardMeld && topCardMeld.filter(c => !c.committed).length >= 2;

        // Give picked up cards to the player if they:
        // - have reached the threshold
        // - melded the 2 natural cards
        // - all melds except for the one from the top card are complete
        const incompleteMelds = team.melds.filter(meld => (topCardMeld || topCardMeld.card.cardRank.value !== meld.card.cardRank.value) && !this.meldIsComplete(meld));
        // if (
        //     hasMeldedRequiredCards &&
        //     this.teamGetTotalMeldPoints(team
    }

    allMeldsComplete(team) {
        return team.melds.every(meld => this.meldIsComplete(meld));
    }

    meldIsComplete(meld) {
        let wildCards = 0;
        let naturalCards = 0;

        for (const meldCard of meld.meldCards) {
            if (meldCard.card.isWild) {
                wildCards++;
            } else {
                naturalCards++;
            }
        }

        if (naturalCards + wildCards > 0 && (naturalCards + wildCards < 3 || naturalCards < 2)) {
            return false;
        }

        return true;
    }

    teamGetTotalMeldPoints(team, opponentTeam, gameConfig, onlyFromCompleteMelds = false) {
        let totalPoints = 0;

        for (const meld of team.melds) {
            if (!onlyFromCompleteMelds || this.meldIsComplete(meld)) {
                for (const meldCard of meld.meldCards) {
                    totalPoints += meldCard.card.meldValue;
                }
            }
        }

        if (gameConfig.swipeCards) {
            for (const meld of opponentTeam.melds) {
                for (const swipedCard of meld.swipedCards) {
                    totalPoints += swipedCard.card.meldValue;
                }
            }
        }

        return totalPoints;
    }

    meldUpdateNaturalFlag(meld) {
        meld.isNatural = meld.meldCards.every(meldCard => !meldCard.card.isWild);
    }


    static CARD_RANKS = [
        {
            "id" : 1,
            "value" : "A",
            "name" : "Ace",
            "sort_order" : 14
        },
        {
            "id" : 2,
            "value" : "2",
            "name" : "Two",
            "sort_order" : 2
        },
        {
            "id" : 3,
            "value" : "3",
            "name" : "Three",
            "sort_order" : 3
        },
        {
            "id" : 4,
            "value" : "4",
            "name" : "Four",
            "sort_order" : 4
        },
        {
            "id" : 5,
            "value" : "5",
            "name" : "Five",
            "sort_order" : 5
        },
        {
            "id" : 6,
            "value" : "6",
            "name" : "Six",
            "sort_order" : 6
        },
        {
            "id" : 7,
            "value" : "7",
            "name" : "Seven",
            "sort_order" : 7
        },
        {
            "id" : 8,
            "value" : "8",
            "name" : "Eight",
            "sort_order" : 8
        },
        {
            "id" : 9,
            "value" : "9",
            "name" : "Nine",
            "sort_order" : 9
        },
        {
            "id" : 10,
            "value" : "10",
            "name" : "Ten",
            "sort_order" : 10
        },
        {
            "id" : 11,
            "value" : "J",
            "name" : "Jack",
            "sort_order" : 11
        },
        {
            "id" : 12,
            "value" : "Q",
            "name" : "Queen",
            "sort_order" : 12
        },
        {
            "id" : 13,
            "value" : "K",
            "name" : "King",
            "sort_order" : 13
        },
        {
            "id" : 14,
            "value" : "Joker Red",
            "name" : "Red Joker",
            "sort_order" : 1
        },
        {
            "id" : 15,
            "value" : "Joker Black",
            "name" : "Black Joker",
            "sort_order" : 1
        }
    ];
}

const gameEngine = new GameEngine();

export function emulateGameAction(originalState, action, cardIds, extraArgs) {
    const state = gameEngine.performPlayerAction(originalState, action, cardIds, extraArgs);
    return state;
}